<template lang="pug">
	.main-wrapper.agendamento-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-2
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-10.ta-right
					h1.text-header.text-secondary MedClub /
						<router-link :to='{ name: "agendamento" }'> Agendamentos</router-link> /
						<b>Calendário</b>

		ProgressBar(v-show='waiting' mode="indeterminate")

		.p-grid.p-justify-center(v-show="! waiting")

			.p-col-8
				Panel(v-if='$refs.calendarioComponent' header='Selecione agenda e mês')

					.p-grid.p-fluid

						.p-col-12.p-md-6
							label.form-label Agenda:
							Dropdown(v-model='$refs.calendarioComponent.filters.cd_agenda' :options='options.agendas' :filter='options.agendas.length > 0'
								optionLabel='nm_especialista' optionValue='id' dataKey='id' placeholder='- Selecione -' @input='$refs.calendarioComponent.getCalendario()')
								template(#option='props')
									span <b>{{ props.option.nm_especialista }}</b><br>
									span {{ props.option.nm_procedimento }}

						.p-col-12.p-md-6
							label.form-label Mês:
							Calendar( v-model='$refs.calendarioComponent.filters.data' dateFormat="mm/yy" :locale="ptbr" :manualInput='false'
								:yearRange="`2020:${proximoAno}`" :yearNavigator='true' view='month' @input='$refs.calendarioComponent.getCalendario()')
		
		
			.p-col-8.mt-1
				CalendarioComponent(ref='calendarioComponent')
</template>

<script>
	import Panel from 'primevue/panel'
	import Dropdown from 'primevue/dropdown'
	import Calendar from 'primevue/calendar'
	import ProgressBar from 'primevue/progressbar'
	import Button from 'primevue/button'
	import CalendarioComponent from './Calendario-component'

	import { pCalendarLocale_ptbr } from './../../utils'
    import { Agenda } from '../../middleware'
    import moment from 'moment'
    
	export default {
		components: { Panel, Dropdown, Calendar, ProgressBar, Button, CalendarioComponent },
		created () {
			this.waiting = true
			Agenda.getEspecialistasAgendados().then(response => {
				this.waiting = false
				if (response.status === 200) {
					let mem = {}
					response.data.forEach(agenda => {
						if (! mem[agenda.id]) {
							mem[agenda.id] = true
							this.options.agendas.push(agenda)
						}
					})
					this.options.agendas.sort((a, b) => a.nm_especialista > b.nm_especialista ? 1 : -1)
				}
			})
		},
		data () {
			return {
				ptbr: pCalendarLocale_ptbr,
				waiting: true,
				proximoAno: parseInt(moment().format("YYYY"))+1,
				options: {
					agendas: []
				}
			}
		}
	}
</script>